.login_box_outer{
  margin:40px;
  animation: logIn 0.3s 1 forwards;
}
@keyframes logIn {
  0% {
    opacity: 0;
    transform: translate3d(0, 5vh, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.login_container{
  margin-bottom: 40px;

  div{
    border: solid 0px var(--ion-text-color);
    margin-bottom: 20px;
  }
  
}

.forgotten{
  margin-top:30px;
  text-align: center;
}
.header{
  margin-bottom: 40px;
}