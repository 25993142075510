
.new_post_button{
  background: var(--ion-background-color);
  border-bottom: solid 1px var(--h2t-border-color);
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
}

.new_post_label{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;

  ion-icon{
    margin-right: 10px;
    font-size: 1.7rem;
  }
}