.container{
  padding: 20px;
  text-align: center;

  p{
    line-height: 1.8;
  }

  .language_select {
    margin-top: 100px;
  }

}

.language_select {
  margin-bottom: 10px;
}

.header{
  text-align: left;
  margin-bottom: 40px;
}
.access_code{
  margin-bottom: 20px;
}
.access_container{
  margin-bottom: 40px;
  text-align: left;

  div{
    border: solid 0 var(--ion-text-color);
    margin-top: 20px;
  }

}

.access_box_outer{
  margin:40px;
  animation: access 0.3s 1 forwards;
}
@keyframes access {
  0% {
    opacity: 0;
    transform: translate3d(0, 5vh, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
/*
.options{
  display: flex;
  flex-direction: column;
  text-align: center;
}
.access_box{
  display: table-cell;
  vertical-align:bottom;
  height:100vh;
  position: relative;
  bottom:40px;
}

@media screen and (min-width: 600px) {
  .access_box{
    display: table-cell;
    vertical-align: middle;
    height:100vh;

  }
  .access_box_inner{
    width:300px;
  }
  .access_box_outer{
    display: block;
    margin:0 auto;
  }
}


 */