.goals_modal_content{
  padding:10px;
}
.goal_filter_enable{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.goal_filter_label{
  margin-right:10px;
}

.goals_header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h1{
    margin: 0px;
  }
}

p{
  line-height: 1.6;
}