.progress_modal_content{
  padding:30px 10px;


  .goal_container {
    display: block;
    flex-direction:column;
    border:solid blue;

    .content{
        margin-bottom: 20px;
        display:block;
        border:solid red;

        .rating{
            width: 80%;
            max-width:300px;
            margin:0 auto 20px auto;
        }
        .details{
            width: 100%;

        }
    }
  }
}

.progress_modal_actions{
  margin:20px 0;
  display:block;
}
.action_button_badge{
  position:absolute;
  right:0;
}
.rating_chart {
  margin-top: 20px;
}

.rating_item{
  width: 100%;
  padding: 10px 0;

}
.rating_item_meeting{
  background:grey;
  color:white;
  border-radius:15px;
  line-height:1em;
  padding:4px 9px;
  font-size:0.8em;
  display:block;
  float:right;
}
