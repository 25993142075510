.date{
  font-size: 12px;
  width: 62px;
  height:62px;
  border-right: solid 0.1px;
  padding: 0;
  text-align: center;
  margin: 0 10px 10px 0;
  float:left;
}
.date .day{
  width:60px;
  height:35px;
  line-height: 35px;
  text-align: center;
  font-size: 35px;
  font-weight: 100;
  display: block; 
}
.date .month{
  width:60px;
  height:25px;
  text-align: center;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
.title{
  font-size: 16px;
  font-weight: bold;
}
.longdate{
  position: relative;
  float: left;
  padding-left: 30px;
  margin-top: 7px;
  font-size:12px;
}
.date_icon{
  font-size:20px;
  position: absolute;
  left: 0;
  top:0;
}