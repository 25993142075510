
.title{
  font-size: 22px!important;
  font-weight: 300;
  margin: 20px 10px 18px 14px;
}

.sub_title{
  font-weight: lighter;
  margin: -10px 10px 20px 14px;
  display: block;
  font-size: 0.8em;;
  line-height: 0.8em;;
}


.item_container{
  display: flex;
  flex-direction: row;
  padding: 15px 0;

  ion-icon{
    margin-left: 10px;
    font-size: 20px;
    flex: 0 0 30px;
  }
  

  div{
    padding: 0 10px;
    font-size: 16px;
    line-height: 20px;
  }

  /* .web_link{
    padding: 0;
    color: blue;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ion-list{
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  ion-item{
    --min-height: 20px;
    --padding-start: 0px;
  } */
}
.item_links{
  line-height: 20px;
}
.item_border{
  border-bottom: solid 1px;
}

.item_attend{
  font-size: 14px;
}

.thumbnail{
  height: 60px;
  width:60px;
  margin-right: 10px;
  border: solid 4px var(--ion-color-secondary-contrast);
}

.no_content{
  padding: 0;
  margin: 0;
  font-style: italic;
}

.ratings{
  padding:20px;
  border-radius: 20px;
}

.rating{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.rating_dial{
  flex: 1;
  margin-right: 20px;
}
.rating_notes{
  flex: 3;
  font-size: 12px;

  h6{
    margin-top: 5px;
  }
}