#root{
  color: var(--ion-text-color);

}

ion-router-outlet > .ion-page ion-content{ 
  animation: fadeIn 0.3s 1 forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate3d(0, 5vh, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// removed for ionic 6
/* ion-modal{
  margin-top: constant(safe-area-inset-top); // for ios 11.1
  margin-top: env(safe-area-inset-top); // for ios 11.2 and onwards
  
  height: calc(100% - constant(safe-area-inset-top));
  // height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-top));
}

ion-modal .modal-wrapper .ion-page{
  padding-bottom: env(safe-area-inset-bottom);
} */

@media (max-width: 1921px) {
  #root {
    font-size: 18px;
  }

  .card-content-ios h1, .card-content-md h1{
    font-size: 28px;
    margin-bottom: 8px;
  }

  .card-content-ios h2, .card-content-md h2{
    font-size: 24px;
    margin-bottom: 8px;
  }

  .card-content-ios h3, .card-content-md h3{
    font-size: 22px;
    margin-bottom: 8px;
  }

  .card-content-ios p, .card-content-md p{
    font-size: 18px;
    line-height: 1.8;
  }
}

@media (max-width: 1441px) {
  #root {
    background-color: red;

    .card-content-ios h1, .card-content-md h1{
      font-size: 20px;
      margin-bottom: 8px;
    }

    .card-content-ios h2, .card-content-md h2{
      font-size: 18px;
      margin-bottom: 8px;
    }

    .card-content-ios h3, .card-content-md h3{
      font-size: 16px;
      margin-bottom: 8px;
    }

    .card-content-ios p, .card-content-md p{
      font-size: 14px;
      line-height: 1.8;
    }
  }
}

@media (max-width: 1025px) {
  #root {
    font-size: 0.8rem;

    h1{
      font-size: 1.5rem;
    }
    h2{
      font-size: 1.35rem;
    }
    h3{
      font-size: 1.2rem;
    }
    h4{
      font-size: 1.05rem;
    }
    h5{
      font-size: 0.95rem;
    }
    p{
      font-size: 0.8rem;
    }

    .card-content-ios h1, .card-content-md h1{
      font-size: 1.5rem;
      margin-bottom: 8px;
    }

    .card-content-ios h2, .card-content-md h2{
      font-size: 1.35rem;
      margin-bottom: 8px;
    }

    .card-content-ios h3, .card-content-md h3{
      font-size: 1.2rem;
      margin-bottom: 8px;
    }

    .card-content-ios h4, .card-content-md h4{
      font-size: 1.05rem;
      margin-bottom: 8px;
    }

    .card-content-ios h5, .card-content-md h5{
      font-size: 0.95rem;
      margin-bottom: 8px;
    }

    .card-content-ios p, .card-content-md p{
      font-size: 0.8rem;
    }
  }
}

@media (min-width: 768px) {
    ion-card, ion-list{
      width:600px;
      margin-left:auto!important;
      margin-right:auto!important;
    }
}

//.toast_message {
//  .toast-wrapper {
//    top: calc(80px + var(--ion-safe-area-top, 0px))!important;
//  }
//}

