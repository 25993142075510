
.media_box_container{
  position: relative;

  video{
    width: 100%;
  }
}

.media_count{
  height: 40%;
  width: 30%;
  border: solid 1px #000;
  color: #fff;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;

  h3{
    font-size: 3em;
  }
}
.modal_content_wrapper{
  height: 100%!important;
  border:solid 1px red;
}





