.modal_content {
  padding: 0;
}
.modal_content_wrapper{
  padding: 0px;
  padding-top:50px;
  height: 100%;
}
ion-modal ion-header{
  position: fixed!important;
}

ion-toolbar {
  --border-style: none;
}


.modal_header ion-icon{
  font-size: 2rem;
}