/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {

    --h2t-border-color: #e0e0e0;
    --ion-font-family: 'Helvetica';
    --ion-text-color: #959595;
    --ion-card-color: #959595;



  /** primary **/
  --ion-color-primary: #0745b9;
  --ion-color-primary-rgb: 7,69,185;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #043694;
  --ion-color-primary-tint: #0652df;

  /** secondary **/
  --ion-color-secondary: #a8c70e;
  --ion-color-secondary-rgb: 214,46,10;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #87a00b;
  --ion-color-primary-tint: #cbf011;

  /** tertiary **/
  --ion-color-tertiary: #f08105;
  --ion-color-tertiary-rgb: 240, 129, 5;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #b46204;
  --ion-color-tertiary-tint: #ffa033;

  /** success **/
  --ion-color-success: #a8c70e;
  --ion-color-success-rgb: 255, 196, 9;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #87a00b;
  --ion-color-success-tint: #cbf011;

  /** warning **/
  --ion-color-warning: #f08105;
  --ion-color-warning-rgb: 240, 129, 5;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #b46204;
  --ion-color-warning-tint: #ffa033;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

ion-backdrop{
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.8)!important;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}
ion-select::part(icon) {
  display: none !important;
 }
 ion-select{
   max-width: 100%!important;
   text-align: right;
  width: 100%;
 }

 ion-card-content ion-icon, ion-item ion-icon{
   color:var(--ion-color-primary);
 }