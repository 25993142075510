.image_gallery_container{
  background-color: #000000;
  display: flex;
  height: 100%;
}

.image_gallery_swiper{
  display: flex;

  justify-content: center;
  align-items: center;
  height: 100%;
  //height: 94vh;
  width: 90%;
  margin: auto;

  img{
    max-height: 90vh!important;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  video{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .swiper-wrapper{
    display: flex;
    align-items: center;
  }
}
.image_gallery_swiper > div > div:last-child{
  display: flex;
  align-items: center;
}
.image_gallery_swiper > div > div:first-child{
  background: rgba(255,255,255,0.1);
}

.image_gallery_swiper > div{
  height:100%;
}