.list_header{
  margin-bottom: 10px;
}

.actions_header{
  margin: 20px 10px 20px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  p{
    margin-right: 20px;
  }
}
.details{
  min-height: 82px;
}
.actions_header_dialog{
  margin: 20px 0 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  p{
    margin-right: 20px;
  }
}

.action_item{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;

  .complete_indicator{
    margin-right: 20px;
  }
  .action_summary{
    flex-grow: 1;

    h5 {
      margin-top: 0;
      overflow: hidden;
    }
  }

  .options{
    margin-left: 10px;
  }
}
.date_wrapper{
  position: relative;
  margin-top:10px;
}
.date{
  font-size:12px;
}
.date_icon{
  font-size:20px;
  position: absolute;
  left: 0;
  top:0;
}
.action_complete{
  flex: 1;

  ion-icon{
    width: 100%;
    height: 100%;
  }
}

ion-icon.complete{
  color: var(--ion-color-success);
}
ion-icon.incomplete{
  color: var(--ion-color-danger);
}

.option_complete{
  background-color: var(--ion-color-danger);
}

.option_incomplete{
  background-color: var(--ion-color-success);
}