
.goal_container {
    display: flex;
    flex-direction: column;

    .content_column {
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .rating{
            width: 70%;
            margin: 10px auto 20px;
        }
    }

    .content_row {
        margin-bottom: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .rating{
            width: 30%;
            margin:0 30px 20px auto;
        }
    }

    .details {
        width: 100%;

        .goal_item_title{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            h4{
                font-weight: bold;
                font-size: 1.2em;
            }

            ion-icon{
                flex-basis: auto;
                font-size: 24px;
                color: var(--ion-color-success);
            }
        }
    }

    .footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        //.added_by{
        //    display: flex;
        //    flex-direction: row;
        //}

        //p{
        //    margin: 0 10px 0 0;
        //    padding: 0;
        //}
    }

}
