.post{
  ion-card-header {
    display: flex;
    align-items: center;
  }

  ion-card-subtitle {
    font-size: 0.8em;
    margin-top: 0;
    margin-left: 5px;
  }
}
.link{
  color: var(--ion-color-primary);
}

.header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: solid 1px var(--h2t-border-color);
  margin-bottom: 5px;
  padding-bottom: 5px;

  .user{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    h3{
      font-weight: bold;
      margin-bottom: 3px !important;
    }

    ion-avatar{
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border: solid 1px var(--h2t-border-color);
    }
  }
}
.post_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p{
    display: flex;
    align-items: center;
    line-height: 1.5em !important;
  }

  .post_date {
    width: 110px;
    margin-right: 5px;

    .date_icon{
      font-size: 1.5em;
      margin:0 5px 0 0;
      color: var(--ion-text-color);
    }
  }

  .post_goal_tag {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
  }

  p{
    font-size: 0.6rem !important;
  }

  ion-icon{
    font-size: 16px;
    margin-left: 10px;
    color: var(--ion-text-color);
  }
}

.post_content{
  padding: 10px 0;
  border-bottom: solid 1px var(--h2t-border-color);
  margin-bottom: 10px;

  p {
    ion-icon {
      padding-left: 5px;
    }
    .speech_started {
      color: red;
    }
  }

  .speech_enabled {
    color: var(--ion-color-primary);
  }
}
.footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;

  div{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .replies p{
    margin-left: 10px;
    margin-top: 3px;
  }
  .likes p{
    margin-right: 10px;
    margin-top: 3px;
  }
}

.latest_reply{
  display: flex;
  flex-direction: row;
  border-top: solid 1px var(--h2t-border-color);
  padding-top: 10px;

  ion-avatar{
    width: 30px;
    height: 30px;
    margin-right: 10px;

    img{
      width: 30px!important;
    height: 30px!important;
    max-width: fit-content!important;
    }
  }

  div{
    width: 100%;
    background-color: var(--ion-color-step-100);    
    padding: 5px;
    border-radius: 5px;

    p{
      margin-bottom: 0;
      margin-top: 0;
    }

    .reply_name{
      font-weight: bold;
    }
  }
}

/* (C2) LEFT */
.reply_left::after {
  /* LEFT TRIANGLE */
  border: 20px solid transparent;
  border-right-color: #a53d38;
  border-left: 0;
  left: -20px;
  top: 50%;
  margin-top: -20px;
  position: absolute;
  content: '';

}