.tab_bar{
  padding-top: 10px;
  padding-left: 0;
  padding-right: 0;
  // padding-bottom: constant(safe-area-inset-top); // for ios 11.1
  // padding-bottom: env(safe-area-inset-top); // for ios 11.2 and onwards
}
.tab_button{
  padding-bottom: 10px;


  ion-icon{
    margin-bottom: 7px;
  }
}

