@use '../../app/app/styles';



.avatar_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  ion-button {
    --background: #fff;
    --color: var(--ion-color-secondary);
    font-weight: bold;

    .profile_video{
      width: 170px;
    }
  }
}

.video_buttons{
  display: flex;
  flex-direction: row;

  input {
    display: none;
  }
}

.avatar_background{
  width:100%;
  height:300px;
  display:block;
  content:"";
  background-color: var(--ion-color-secondary);
  position: absolute;
  z-index: -1000;
}

.avatar{
  width: 100%;
  height: 100%;
  margin:0 auto;
  text-align: center;
}
.avatar_img{
  width:120px;
  height:120px;
  border:solid 3px var(--ion-color-primary-contrast)
}


.avatar_content{

  padding-top:30px!important;
  padding-bottom:30px;

}

.questions{

  line-height: 1;

  h2 {
    position: relative;
    font-size: 1.2rem!important;
    font-weight: 100;
    line-height: 1;
    margin: 0;
  }
  p {
    position: relative;
    font-size: 1.1rem!important;
    font-weight: normal;
    line-height: 1.1!important;
    margin: 0;
    padding-top: 20px;
    z-index: 1;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }


  /* Blockquote main style */
  blockquote {
    position: relative;

    font-weight: 800;
    padding: 30px 0;
    width: 100%;
    max-width: 500px;
    z-index: 1;
    margin: 0 auto 80px auto;
    align-self: center;
    border-bottom: solid 1px;
  }

  /* Blockquote right double quotes */
  blockquote:after {
    position: absolute;
    content: "”";
    font-size: 10rem;
    line-height: 0;
    bottom: -43px;
    right: 30px;
    font-family: 'Montserrat', sans-serif;
  }
}