.can_not_edit_text{
  margin-top: 10px;

  p{
    line-height: 1.4 !important;
  }
}

.save_button{
  margin-bottom: 0;
  margin-right:8px;
  margin-left:8px;
}
.cardpadless{
  padding-left: 0;
  padding-right: 0;
}
.date_picker{

  input{
    border: none;
    width: 100%;
    padding:8px 10px 8px 0;
    background-color: transparent;
    text-align: right;
    width: 100%;
  }
  input::placeholder {
    opacity: 0.6; /*Change the opacity between 0 and 1*/
}
}