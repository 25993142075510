.can_not_edit_text{
  margin-top: 10px;

  p{
    line-height: 1.4 !important;
  }
}

.save_button{
  margin-bottom: 0;
  margin-right:8px;
  margin-left:8px;
}
.cardpadless{
  padding-left: 0;
  padding-right: 0;
}