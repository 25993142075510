.no_content{
  padding: 10px;
  margin: 10px;
  text-align: center;

  ion-icon{
    font-size: 40vw;
  }
}

h1{
  font-weight: bold;
}

