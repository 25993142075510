.group_action{
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.90);

  div{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  .pin {
    width: 300px;
    border-bottom: solid 1px #336699;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
    margin: 10px auto;
    text-align: center;
    font-size: 38px;
    -webkit-text-security:circle;
  }
}