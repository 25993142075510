
.goal_modal{
}
.goal_card{

}
.goal_modal .content{
  padding: 10px;
}
ion-content > ion-card:last-of-type{
  margin-bottom: 80px;
}

/* The animation code */
@keyframes cardfade {
  from {opacity:0; transform: scale(0.1);}
  to {opacity:1; transform: scale(1);}
}

/* The element to apply the animation to */
ion-card {
  opacity:1;
  transform: scale(1);
  animation-name: cardfade;
  animation-duration: 0.4s;
}