.goal_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .complete {
    color: var(--ion-color-success);
    font-size: 20px;

  }
  .incomplete {
    color: var(--ion-color-danger);
    font-size: 20px;
  }
}
