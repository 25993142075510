.header_hidden ion-header{ 
    transform: translate(0px,-59px);
    transition-duration: 0.25s;
}
.header_visible ion-header{
    transform: translate(0px,0px);
    transition-duration: 0.25s;
}

.header_visible ion-buttons, .header_visible ion-grid{
    opacity: 1;  
    transition: all 0.25s ease-out;
}
.header_hidden ion-buttons, .header_hidden ion-grid{
    opacity: 0;
    transition: all 0.25s ease-out;
}


.fab_hidden{
    opacity : 0;
    transition:opacity 1s;
}
.fab_visible{
    opacity : 1;
    transition:opacity 1s;
}