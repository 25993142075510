.pic_container{

position: relative;
display: flex;
justify-content: center;

ion-icon{
font-size: 24px;
}
}

.avatar_container {
   //position: absolute;
   width: 300px;
   margin:25px auto;
  
}

.avatar{
   width: 300px;
   height: 300px;
  border: solid 5px #ddd;
//   position: absolute;
 }

.edit_avatar{
   position: absolute;
   width: 300px;
  padding-bottom: 0;
   display: flex;
  margin-top:50px;

   ion-icon{
    font-size: 24px;
     position: absolute;
    bottom: 0;
     right: 0;
  }
 }

.account_details{
//   padding: 10px;

  h1{
     text-align: center;
     margin-bottom:25px;
   }
 }

// .settings_container{
//   max-width: 350px;
//   margin: 0 auto;

//   ion-item{
//     background-color: #0b93ff;
//   }
// }

// .button_container {
//   position: fixed;
//   bottom: 0;
//   width: 100%;
//   padding: 10px;
// }