.rating_bar_wrapper{
  width: 100%;
  margin: 10px 0px;
}

.rating_bar_details{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 5px;
}
.rating_bar_score{
  display: block;
}
.rating_bar_title{
  display:block
}
.rating_bar_score_amount{
  font-size:2em;
}

.meeting_label{
  font-size: 10px;
}

.progress_div {
  background-color: #fff;
  border: solid 2px var(--h2t-border-color);
  padding: 3px;
  border-radius:30px;
}

.progress {
  height: 16px;
  border-radius: 0.7rem;
  transition: 1s ease;
  transition-delay: 0.5s;
}