
ion-col {
  text-align: left;
}
.page_header{
  border: solid 0px
}
.page_title{
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.page_sub_title{
  font-size: 0.7rem;
}

.right_buttons{
  width:48px;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px; 
}
@media (prefers-color-scheme: light) {
  .header_toggle{
    border-bottom: solid 1px #ddd;
  }

}

@media (prefers-color-scheme: dark) {
  .header_toggle{
    border-bottom: solid 1px #0d0d0d;
  }

}