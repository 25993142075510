
.user_bar{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  h6{
    margin: 10px;
  }
}
.cardpadless{
  padding-left: 0;
  padding-right: 0;
}
.save_button{
  margin-bottom: 0;
  margin-right:8px;
  margin-left:8px;
}

.options{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8rem;
  position: absolute;
  right: 10px;

  div{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
  ion-icon{
    color:var(--ion-color-primary);
    font-size: 18px;
    margin-right: 5px;
    margin-bottom:0;
  }
}

.options_list{
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  margin-left: 10px;

  ion-icon{
    color:var(--ion-color-primary);;
    font-size: 18px;
    margin-right: 5px;
  }
}

.chip{
  color: var(--ion-text-color);
  border-color: var(--h2t-border-color);
  border-radius: 5px;
  font-size: 0.8rem;

  .video_label{
    display: flex;
  }
}

.video_input{
  display: none;
}

.media_container{
  display: flex;
  flex-wrap: wrap;

  div{
    border: solid 0.25px var(--h2t-border-color);
    border-radius: 2px;
    display: flex;
  }

  img{
    height: 50px;
  }

  video{
    height: 50px;
  }

  ion-icon{
    color: var(--ion-color-primary);;
    font-size: 18px;
    margin-right: 5px;
  }
}
.media_card{
  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: 6px;
  padding-inline-start: 6px;
  -webkit-padding-end: 6px;
  padding-inline-end: 6px;
  padding-top: 6px;
  padding-bottom: 6px; 
}
