.reply_modal_content{
  margin:50px 0 60px 0;
}
.reply{
  
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  flex-grow: 1;
  border-left: solid 2px var( --ion-color-secondary);
  width:100%;
  background:rgba(0,0,0,0.1);

    h3{
      font-weight: lighter;
      margin: 0 !important;
      font-size: 0.8em!important;
    }
    P {
      margin-bottom:0;

      ion-icon {
        padding-left: 5px;
      }

      .speech_started {
        color: red;
      }
    }

  .speech_enabled {
    color: var(--ion-color-primary);
  }
}
.reply_details{
  flex-grow: 1;
}
.reply_wrapper{
  display:flex;
  flex-direction: row;
  justify-content:left;
  align-items:flex-start;
  margin:10px;

  ion-avatar{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-top:4px;
    border: solid 1px var(--h2t-border-color);
  }
}

.post_date{
  p{
    font-size: 0.6rem !important;
    display: flex;
  }
  .date_icon{
    font-size: 1rem !important;
    margin:0 5px 0 10px;
  }
}

.reply_box{
  //border-top: solid 1px var(--h2t-border-color);
  margin: 0;
  padding: 10px 10px 20px 10px;
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left:0;
  width:100%;
  background:var(--ion-background-color, #ffffff);

  ion-input{
    border: solid 1px var(--h2t-border-color);
    border-radius: 25px;
    padding-left:15px!important;
  }
}
.reply_button{
  margin:1px 0 0 10px!important;
  border-radius:25px;
}
.reply_icon{
  margin-left:10px;
  line-height: 39px;
  margin-bottom: 0;
  padding: 0;
  height: 39px;
  width:39px;
}
.like_container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin:10px;

  p{
    margin-right: 10px;
  }

  .like_link{
    color: var(--ion-color-primary);
  }
}